export interface Profile {
  name: string;
  logo: string;
  theme: string;
  properties: any;
}

export const cityLeiden: Profile = {
  name: 'Gemeente Leiden',
  logo: '/assets/logo.png',
  theme: 'dark',
  properties: {
    menuHeaderTitle: '',
  },
};

export const cityMeppel: Profile = {
  name: 'Gemeente Meppel',
  logo: '',
  theme: 'dark',
  properties: {
    menuHeaderTitle: 'Meppel',
  },
};
