import { Injectable } from '@angular/core';
import { cityLeiden, cityMeppel, Profile } from '../profile/profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private active: Profile;

  constructor() {
    this.active = cityLeiden;
  }

  getName(): string {
    return this.active.name;
  }

  getLogo(): string {
    return this.active.logo;
  }

  getProperties(): any {
    return this.active.properties;
  }
}
