import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ProfileService } from './services/profile.service';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  logo: string;
  title: string;

  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Sensoren', url: '/markers', icon: 'map' },
    { title: 'Dashboard', url: '/dashboard', icon: 'paper-plane' },
  ];

  constructor(
    private menu: MenuController,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.logo = this.profileService.getLogo();
    this.title = this.profileService.getProperties().menuHeaderTitle;
  }
}
